import React, {Component} from 'react';
import CallToAction from '../../components/CallToAction/CallToAction';
import Aux from '../../hoc/Aux/Aux';
import CampaignFlow from '../../components/UI/ResponsiveImageSet/ResponsiveImageSet';
import CampaignFlowLg from '../../assets/images/chrismongemeinde2023-magazincover-rgb.jpg';
import CallToActionPlaceholder from "../../components/UI/Placeholder/CallToActionPlaceholder/CallToActionPlaceholder";
import {cleanLocationProp} from "../../shared/utility";
import {Row, Col} from 'reactstrap';
import axios from '../../axios-base';
import {Helmet} from "react-helmet";

class home extends Component {
    constructor(props){
        super(props);
        this.state = {
            ctas: null
        };
    };

    componentDidMount = () => {
        axios.get('/cta/', {
            params: {
                page: cleanLocationProp(this.props.location.pathname)
            }})
        .then(response => {
            this.setState({ctas: response.data});
        })
        .catch(error => {
            this.setState({error: true});
        });
    };

    render() {
        let cta = (
            <React.Fragment>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
            </React.Fragment>
        );
        if (this.state.ctas){
            cta = this.state.ctas.map((cta, key)=> (
                <React.Fragment key={key} >
                    {key === 2 ?
                        <Col sm="6" className="my-3 d-flex align-items-start flex-column">
                            <CampaignFlow imageXs={CampaignFlowLg} imageLg={CampaignFlowLg} />
                        </Col>
                    : null}
                    <Col sm="6" className="my-3 d-flex align-items-start flex-column">
                        <CallToAction
                            headline={cta.headline}
                            copy={cta.copy}
                            buttonText={cta.buttonText}
                            buttonColor={cta.buttonColor}
                            linkUrl={cta.linkUrl}
                            outline={cta.outline}
                            buttonVisibility={cta.buttonVisibility}
                        />
                    </Col>
                </ React.Fragment>
            ));
        }

        return (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Willkommen`}</title>
                </Helmet>
                <Row>
                    {cta}
                </Row>
            </Aux>
        );
    }
}

export default home;
